import React from 'react'
import { Link } from 'react-router-dom'
import styles from './LandingPage.module.css' // Import the CSS module

// Assuming you have a favicon.ico in your public folder
import logo from '../assets/png/favicon-9.png'

const LandingPage: React.FC = () => {
  return (
    <div className={styles.landingPage}>
      {' '}
      {/* Use styles from the CSS module */}
      <div className={styles.header}>
        <img src={logo} alt="hotlines.ai logo" className={styles.logo} />
        <h1 className={styles.title}>hotlines.ai</h1>
      </div>
      <p className={styles.tagline}>effortless business engagement</p>
      <p className={styles.comingSoon}>
        Have access? <Link to="/login">Login</Link>
      </p>
    </div>
  )
}

export default LandingPage
