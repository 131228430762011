import { ChevronLeftIcon, ChevronRightIcon } from '@radix-ui/react-icons'
import { Card, Flex, IconButton } from '@radix-ui/themes'
import React, { useState } from 'react'

interface RestaurantPhotosProps {
  photosArray: string[]
}

const RestaurantCarousel: React.FC<RestaurantPhotosProps> = ({
  photosArray,
}) => {
  const [currentIndex, setCurrentIndex] = useState(0)

  const prevSlide = () => {
    setCurrentIndex((prev) => (prev === 0 ? photosArray.length - 1 : prev - 1))
  }

  const nextSlide = () => {
    setCurrentIndex((prev) => (prev === photosArray.length - 1 ? 0 : prev + 1))
  }

  return (
    <Card className="relative w-full max-w-lg mx-auto overflow-hidden shadow-sm">
      {/* Image Container */}
      <div className="w-full h-64 bg-gray-200">
        <img
          src={photosArray[currentIndex]}
          alt="Restaurant Interior"
          className="w-full h-full object-cover transition-opacity duration-500"
        />
      </div>

      {/* Navigation Buttons */}
      <Flex
        justify="between"
        className="absolute top-1/2 left-0 right-0 -translate-y-1/2 px-4"
      >
        <IconButton
          onClick={prevSlide}
          aria-label="Previous"
          className="bg-white/70 hover:bg-white"
        >
          <ChevronLeftIcon />
        </IconButton>
        <IconButton
          onClick={nextSlide}
          aria-label="Next"
          className="bg-white/70 hover:bg-white"
        >
          <ChevronRightIcon />
        </IconButton>
      </Flex>
    </Card>
  )
}

export default RestaurantCarousel
