/* 
 * Usage getMostCommonColor("URL")
  .then((color) => {Do something})
  .catch((error) => console.error(error));
*/
export const getMostCommonColor = (
  imageUrl: string,
  scaleFactor: number = 0.1
): Promise<string> => {
  return new Promise((resolve, reject) => {
    const img = new Image()
    img.crossOrigin = 'Anonymous' // Handle CORS issues
    img.src = imageUrl

    img.onload = () => {
      const canvas = document.createElement('canvas')
      const ctx = canvas.getContext('2d')
      if (!ctx) {
        reject('Canvas is not supported.')
        return
      }

      // Resize image to reduce processing time (scaleFactor = 0.1 means 10% of original size)
      const width = Math.max(1, Math.floor(img.width * scaleFactor))
      const height = Math.max(1, Math.floor(img.height * scaleFactor))
      canvas.width = width
      canvas.height = height
      ctx.drawImage(img, 0, 0, width, height)

      // Get pixel data
      const imageData = ctx.getImageData(0, 0, width, height)
      const pixels = imageData.data

      const colorCounts: Record<string, number> = {}
      let mostCommonColor = ''
      let maxCount = 0

      // Process pixels (RGBA format: [r, g, b, a])
      for (let i = 0; i < pixels.length; i += 4) {
        const r = pixels[i]
        const g = pixels[i + 1]
        const b = pixels[i + 2]
        const a = pixels[i + 3]

        // Skip transparent pixels
        if (a === 0) continue

        // Reduce color depth to avoid too many unique colors (binning technique)
        const key = `${Math.floor(r / 16) * 16},${Math.floor(g / 16) * 16},${Math.floor(b / 16) * 16}`

        // Count occurrences
        colorCounts[key] = (colorCounts[key] || 0) + 1

        // Track most common color dynamically
        if (colorCounts[key] > maxCount) {
          maxCount = colorCounts[key]
          mostCommonColor = `#${(Math.floor(r / 16) * 16).toString(16).padStart(2, '0')}${(
            Math.floor(g / 16) * 16
          )
            .toString(16)
            .padStart(
              2,
              '0'
            )}${(Math.floor(b / 16) * 16).toString(16).padStart(2, '0')}`
        }
      }

      resolve(mostCommonColor)
    }

    img.onerror = () => reject('Failed to load image.')
  })
}
