import React from 'react'
import favicon from '../assets/png/favicon-9.png'

const LoadingComponent: React.FC = () => {
  return (
    <div
      className="flex flex-col items-center justify-center min-h-screen"
      style={{
        background: 'linear-gradient(to bottom, #EDF5FE, #F4F2FB, #FBF0F7)',
      }}
    >
      <img
        src={favicon}
        alt="Loading..."
        className="w-16 h-16 animate-pulse"
        style={{
          animation: 'scaleAnimation 1.5s infinite ease-in-out',
        }}
      />
    </div>
  )
}

export default LoadingComponent
