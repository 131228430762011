export enum BusinessType {
  BUSINESS = 'BUSINESS',
  AI = 'AI',
}

export interface Business {
  id: string
  username: string
  name: string
  phone: string
  avatar?: string
  email?: string
  description: string
  website?: string
  type: BusinessType
  actions: BusinessActions[]
  cover_image?: string
  carousel_1?: string[]
  carousel_2?: string[]
}

export interface BusinessActions {
  name: string
  action: string
}
