import { Badge } from '@radix-ui/themes'
import { Session } from '@supabase/supabase-js'
import { formatDistanceToNow } from 'date-fns'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { getChats, Profile } from '../services/api'
import { BusinessType, Chat } from '../types'
import BusinessAvatar from './BusinessAvatar'
import LoadingComponent from './LoadingComponent'
import PageContainer from './PageContainer'

interface ChatListProps {
  profile: Profile | null
  session: Session
}

const ChatList: React.FC<ChatListProps> = ({ profile, session }) => {
  const [chats, setChats] = useState<Chat[]>([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const fetchChats = async () => {
      try {
        setLoading(true)
        const response = await getChats(session)
        setChats(response.data)
      } catch (error) {
        console.error('Error fetching chats:', error)
      } finally {
        setLoading(false)
      }
    }

    fetchChats()
  }, [session])

  if (loading) {
    return (
      <PageContainer session={session} profile={profile}>
        <LoadingComponent />
      </PageContainer>
    )
  }

  return (
    <PageContainer session={session} profile={profile}>
      <div className="flex-grow overflow-y-auto pb-16">
        {chats.length === 0 ? (
          <div className="flex flex-col items-center justify-center h-full text-gray-500">
            <p className="text-lg">No chats yet</p>
          </div>
        ) : (
          chats
            .filter((chat) => chat.lastMessage && chat.lastMessageTimestamp)
            .sort(
              (a, b) =>
                new Date(b.lastMessageTimestamp).getTime() -
                new Date(a.lastMessageTimestamp).getTime()
            )
            .map((chat) => (
              <Link
                to={`/chat/${chat.id}`}
                key={chat.id}
                state={{ businessInfo: chat.business }}
                className={`block px-4 py-4 bg-white bg-opacity-10 transition-colors border-b border-gray-200`}
              >
                <div className="flex justify-between items-start">
                  <div className="flex items-center space-x-3">
                    <BusinessAvatar
                      src={chat.business.avatar}
                      username={chat.business.username}
                      size="md"
                    />
                    <div className="flex-grow min-w-0">
                      <div className="flex items-center space-x-1">
                        <div className="font-semibold">
                          {chat.business.name}
                        </div>
                        {chat.business.type === BusinessType.AI && (
                          <Badge variant="outline" color="blue" radius="full">
                            AI
                          </Badge>
                        )}
                      </div>
                      <div className="text-sm text-gray-500 truncate">
                        {chat.lastMessage.length > 29
                          ? `${chat.lastMessage.substring(0, 29)} ...`
                          : chat.lastMessage}
                      </div>
                    </div>
                  </div>
                  <span className="text-sm text-gray-400">
                    {chat.lastMessageTimestamp
                      ? formatDistanceToNow(
                          new Date(chat.lastMessageTimestamp),
                          {
                            addSuffix: true,
                          }
                        )
                      : ''}
                  </span>
                </div>
              </Link>
            ))
        )}
      </div>
    </PageContainer>
  )
}

export default ChatList
