import { PaperPlaneIcon } from '@radix-ui/react-icons'
import {
  Badge,
  Box,
  Button,
  Card,
  Flex,
  Heading,
  IconButton,
  Separator,
  Text,
} from '@radix-ui/themes'
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import useMessagingManager from '../hooks/useMessagingManager'
import { getBusinessInfo, getTransactionsUserBusiness } from '../services/api'
import {
  Business,
  BusinessActions,
  BusinessType,
  CarTransaction,
  FlightTransaction,
  TableTransaction,
  Transaction,
} from '../types'
import { handleSpecialScenarios } from '../utils/specialScenarioHandler'
import BusinessAvatar from './BusinessAvatar'
import { BusinessProfileProps } from './BusinessProfilePage'
import LoadingComponent from './LoadingComponent'
import PageContainer from './PageContainer'
import CarCard from './TransactionCards/CarCard'
import FlightCard from './TransactionCards/FlightCard'
import TableCard from './TransactionCards/TableCard'

const BusinessProfileMobile: React.FC<BusinessProfileProps> = ({
  session,
  profile,
}) => {
  const { businessId } = useParams<{ businessId: string }>()
  const navigate = useNavigate()

  const [businessData, setBusinessData] = useState<Business | null>(null)
  const [transactionData, setTransactionData] = useState<Transaction[] | null>(
    null
  )
  const [loading, setLoading] = useState(true)
  const [expanded, setExpanded] = useState(false)
  const [message, setMessage] = useState<string>('')

  const {
    chatId,
    isCreatingChat,
    isMessageSending,
    sendMessage,
    handleUserMessageTyping,
  } = useMessagingManager(businessId ?? '', session)

  useEffect(() => {
    const fetchBusiness = async () => {
      try {
        setLoading(true)

        const [businessResponse, transactionResponse] = await Promise.all([
          getBusinessInfo(businessId ?? '').catch((error) => {
            console.error('Error fetching business info:', error)
            return { data: null }
          }),
          getTransactionsUserBusiness(
            session.user.id,
            businessId ?? '',
            session
          ).catch((error) => {
            console.error('Error fetching transactions:', error)
            return { data: [] }
          }),
        ])

        setBusinessData(businessResponse.data)
        setTransactionData(transactionResponse.data)
      } catch (error) {
        console.error('Error in concurrent fetching Promise.all:', error)
      } finally {
        setLoading(false)
      }
    }
    fetchBusiness()
  }, [businessId, session])

  const handleActionClick = async (
    action: BusinessActions,
    processedActionString?: string
  ) => {
    if (processedActionString) {
      handleSendMessage(processedActionString)
    } else {
      handleSendMessage(action.action)
    }
  }

  const handleSendMessage = async (content: string = '') => {
    if (message.length === 0 && content.length === 0) return

    const messageToSend = content || message

    await sendMessage(messageToSend, async (newChatId) => {
      await handleSpecialScenarios(messageToSend, session)
      navigate(`/chat/${newChatId}`, { state: { businessInfo: businessData } })
    })

    setMessage('') // Clear input after sending
  }

  if (loading) {
    return (
      <PageContainer
        session={session}
        profile={profile}
        headerLeftElement="icon"
      >
        <LoadingComponent />
      </PageContainer>
    )
  }

  // Handle case when data is not loaded.
  if (!businessData || !transactionData) {
    return (
      <PageContainer
        session={session}
        profile={profile}
        headerLeftElement="back"
        headerTransparent={false}
      >
        <div className="p-4">
          <h1 className="text-xl font-semibold">Business not found</h1>
          <p className="text-gray-600 mt-2">
            The business you're looking for doesn't exist or has been removed.
          </p>
        </div>
      </PageContainer>
    )
  }

  return (
    <PageContainer
      session={session}
      profile={profile}
      headerLeftElement="back"
      headerTransparent={false}
    >
      <div className="flex flex-col flex-grow min-h-full justify-between">
        <div>
          {/* Profile Section */}
          <Box p="4">
            <Flex gap="4" align="center">
              <BusinessAvatar
                src={businessData.avatar}
                username={businessData.username}
                size="lg"
              />
              <Box>
                <Flex align="center" gap="2">
                  <Heading>{businessData.name}</Heading>
                  {businessData.type === BusinessType.AI && (
                    <Badge variant="outline" color="blue" radius="full">
                      AI
                    </Badge>
                  )}
                </Flex>
                <Text>{businessData.username}</Text>
              </Box>
            </Flex>
            <Flex
              maxWidth="100%"
              mt="4"
              align="center"
              justify="between"
              gap="2"
            >
              <div
                className={`relative flex-1 ${expanded ? '' : 'truncate'}`}
                onBlur={() => setExpanded(false)}
                onClick={() => setExpanded(!expanded)}
              >
                {businessData.description}
              </div>
            </Flex>
          </Box>
          <Separator my="3" size="4" />

          <Card className="m-4 shadow-sm" variant="surface">
            <Flex direction="column" gap="1">
              <Heading
                size="3"
                className="bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500
                      text-transparent bg-clip-text font-extrabold tracking-tight mb-2"
              >
                Start a chat ✨
              </Heading>

              <Flex
                gap="2"
                wrap="nowrap"
                justify="start"
                className="overflow-x-auto [&::-webkit-scrollbar]:hidden [-ms-overflow-style:none] [scrollbar-width:none]"
              >
                {businessData.actions?.length > 0 ? (
                  businessData.actions.map((action: BusinessActions) => (
                    <Button
                      size="3"
                      radius="large"
                      key={action.name}
                      className="flex-shrink-0"
                      variant="surface"
                      onClick={() => handleActionClick(action)}
                    >
                      {action.name}
                    </Button>
                  ))
                ) : (
                  <></>
                )}
              </Flex>
            </Flex>
            {/* Search Input */}
            <Flex justify="center" align="center" gap="2" mt="4">
              <div className="max-w-screen-md mx-auto flex bg-white w-11/12 rounded-xl px-4 py-2 shadow-sm border border-gray-400 items-center">
                <input
                  type="text"
                  placeholder={`How can we help you today?`}
                  value={message}
                  onChange={(e) => handleUserMessageTyping(e, setMessage)}
                  className="bg-transparent w-full outline-none"
                />
              </div>
              <IconButton
                onClick={() => handleSendMessage()}
                loading={isMessageSending || isCreatingChat}
                aria-label="Send Message"
                disabled={!message.length}
              >
                <PaperPlaneIcon />
              </IconButton>
            </Flex>
          </Card>

          {chatId && (
            <div className="flex justify-center px-4">
              <Button
                variant="ghost"
                onClick={() => {
                  // Navigate to chat using the retrieved or existing chat ID
                  navigate(`/chat/${chatId}`, {
                    state: { businessInfo: businessData },
                  })
                }}
              >
                View past interactions
              </Button>
            </div>
          )}

          <Separator my="3" size="4" />

          {/* Transactions Section */}

          {(transactionData || []).length === 0 ? (
            <div />
          ) : (
            <Flex align="center" justify="center" gap="1" p="1" px="4">
              <Text size="4" weight="bold">
                Highlights
              </Text>
              <Badge radius="full">{transactionData.length}</Badge>
            </Flex>
          )}

          <Flex dir="col" align="center" justify="start" gap="4" p="4">
            {(transactionData || []).length === 0 ? (
              <div />
            ) : (
              transactionData.map((data) => {
                switch (data.type) {
                  case 'flight':
                    const flight = data as FlightTransaction
                    return (
                      <FlightCard
                        key={flight.id}
                        from={flight.data.from}
                        to={flight.data.to}
                        date={flight.data.date}
                        time={flight.data.time}
                        status={flight.data.status}
                        number={flight.data.number}
                        pnr={flight.data.pnr}
                        toImage={flight.data.toImage}
                        actions={flight.actions}
                        onActionClick={handleActionClick}
                      />
                    )
                  case 'table':
                    const table = data as TableTransaction
                    return (
                      <TableCard
                        key={table.id}
                        date={table.data.date}
                        time={table.data.time}
                        people={table.data.people}
                        status={table.data.status}
                        actions={table.actions}
                        onActionClick={handleActionClick}
                      />
                    )
                  case 'car':
                    const car = data as CarTransaction
                    return (
                      <CarCard
                        key={car.id}
                        number_plate={car.data.number_plate}
                        last_service={car.data.last_service}
                        icon={car.icon}
                        model={car.data.model}
                        actions={car.actions}
                        onActionClick={handleActionClick}
                      />
                    )
                  default:
                    return null
                }
              })
            )}
          </Flex>
        </div>
      </div>
    </PageContainer>
  )
}

export default BusinessProfileMobile
