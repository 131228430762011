import React from 'react'
import { Message } from '../types'
import { format } from 'date-fns'

interface ChatMessageProps {
  message: Message
}

const ChatMessage: React.FC<ChatMessageProps> = ({ message }) => {
  const isCustomer = message.profiles.type === 'customer'
  return (
    <div className={`flex ${isCustomer ? 'justify-end' : 'justify-start'} mb-4`}>
      <div
        className={`max-w-[80%] rounded-2xl px-4 py-3 ${
          isCustomer
            ? 'bg-blue-600 text-white rounded-tr-none'
            : 'bg-gray-100 text-gray-800 rounded-tl-none'
        }`}
      >
        <p className="text-[15px] leading-relaxed">{message.content.text}</p>
        <span className={`text-xs ${isCustomer ? 'text-blue-100' : 'text-gray-500'} mt-1 block`}>
          {format(new Date(message.created_at), 'MMM d, yyyy h:mm a')}
        </span>
      </div>
    </div>
  )
}

export default ChatMessage 