import { Session } from '@supabase/supabase-js'
import React, { ReactNode } from 'react'
import { Profile } from '../services/api'
import AppHeader from './AppHeader'
import BottomNavigation from './BottomNavigation'

interface PageContainerProps {
  children: ReactNode
  session: Session | null
  profile: Profile | null
  className?: string
  headerLeftElement?: string
  headerTransparent?: boolean
}

const PageContainer: React.FC<PageContainerProps> = ({
  children,
  session,
  profile,
  className = '',
  headerLeftElement = 'icon',
  headerTransparent = false,
}) => {
  return (
    <div
      className={`flex flex-col justify-between min-h-screen ${className}`}
      style={{
        background: 'linear-gradient(to bottom, #EDF5FE, #F4F2FB, #FBF0F7)',
      }}
    >
      <div className="flex flex-col flex-grow min-h-full">
        <AppHeader
          session={session}
          profile={profile}
          leftElement={headerLeftElement}
          isTransparent={headerTransparent}
        />
        {children}
      </div>
      <div>
        <BottomNavigation />
      </div>
    </div>
  )
}

export default PageContainer
