import React, { useEffect, useRef } from 'react'
import { Message } from '../types'
import ChatMessage from './ChatMessage'

interface MessagesContainerProps {
  messages: Message[]
}

const MessagesContainer: React.FC<MessagesContainerProps> = ({ messages }) => {
  const messagesEndRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'auto' })
  }, [messages])

  return (
    <div className="flex-1 flex flex-col h-full relative">
      <div 
        className="absolute inset-0 overflow-y-auto [&::-webkit-scrollbar]:hidden [-ms-overflow-style:none] [scrollbar-width:none]"
      >
        <div className="p-6 space-y-2">
          {messages.length === 0 ? (
            <div className="flex flex-col items-center justify-center h-full text-gray-400">
              <p className="text-lg">Start a conversation</p>
              <p className="text-sm">Your messages will appear here</p>
            </div>
          ) : (
            messages.map((message) => (
              <ChatMessage key={message.id} message={message} />
            ))
          )}
          <div ref={messagesEndRef} />
        </div>
      </div>
    </div>
  )
}

export default MessagesContainer 