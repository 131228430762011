import React, { useEffect, useState } from 'react'
import { Business } from '../../types'
import { getMostCommonColor } from '../../utils/colourHandler' // Import your processing function

interface CoverImageProps {
  businessData: Business
}

const CoverImage: React.FC<CoverImageProps> = ({ businessData }) => {
  const [processedColor, setProcessedColor] = useState<string | null>(null)
  const [imageLoaded, setImageLoaded] = useState(false)

  useEffect(() => {
    const processImage = async () => {
      if (businessData.cover_image) {
        // If cover image exists, don't need to do any color processing
        setImageLoaded(true)
        return
      }

      if (businessData.avatar) {
        try {
          // Use avatar to calculate the dominant color if no cover image
          const dominantColor = await getMostCommonColor(businessData.avatar)
          setProcessedColor(dominantColor)
          setImageLoaded(true)
        } catch (error) {
          console.error('Error processing avatar image:', error)
        }
      } else {
        // No image available, set as transparent cover
        setProcessedColor(null)
        setImageLoaded(true)
      }
    }

    processImage()
  }, [businessData.cover_image, businessData.avatar])

  if (!imageLoaded) return <div className="w-full h-80 bg-transparent" />

  // If there is a cover image, use it as the background
  if (businessData.cover_image) {
    return (
      <div
        className="w-full h-80 bg-cover bg-center"
        style={{
          backgroundImage: `url(${businessData.cover_image})`,
        }}
      />
    )
  }

  // If no cover image but we have a dominant avatar color, use it as background
  if (processedColor) {
    return (
      <div
        className="w-full h-80 bg-cover bg-center"
        style={{
          backgroundColor: processedColor,
        }}
      />
    )
  }

  // Fallback to transparent background if no image or avatar color is available
  return <div className="w-full h-80 bg-transparent" />
}

export default CoverImage
