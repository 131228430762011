import { Session } from '@supabase/supabase-js'
import axios from 'axios'
import { v4 as uuidv4 } from 'uuid'
import { Business, Chat, Message, Post, Transaction } from '../types'

const API_BASE_URL =
  process.env.REACT_APP_API_BASE_URL ||
  `http://localhost:${process.env.REACT_APP_SERVER_PORT || 5000}/api` // Fallback for local development

// Interfaces

export interface Profile {
  account_id: string | null
  created_at: string
  first_name: string | null
  last_name: string | null
  profile_id: string
  type: 'customer' | 'business_human' | 'business_ai'
}

// API functions
export const getBusinessList = async (): Promise<{ data: Business[] }> => {
  const response = await axios.get(`${API_BASE_URL}/business`)
  return response['data']
}

export const getBusinessInfo = async (
  business_id: string
): Promise<{ data: Business }> => {
  const response = await axios.get(`${API_BASE_URL}/business/${business_id}`)
  return response['data']
}

export const getBusinessPosts = async (
  businessId: string
): Promise<{ data: Post[] }> => {
  const response = await axios.get(`${API_BASE_URL}/posts/${businessId}`)
  return response.data
}

export const getChats = async (session: Session): Promise<{ data: Chat[] }> => {
  const response = await axios.get(`${API_BASE_URL}/chats`, {
    headers: {
      Authorization: `Bearer ${session.access_token}`,
    },
  })
  return response.data
}

export const getChatMessages = async (
  chatId: string,
  session: Session
): Promise<{ data: Message[] }> => {
  const response = await axios.get(`${API_BASE_URL}/chats/${chatId}/messages`, {
    headers: {
      Authorization: `Bearer ${session.access_token}`,
    },
  })
  return response.data
}

export const sendMessage = async (
  chatId: string,
  content: string,
  session: Session
): Promise<{ data: Message }> => {
  const newMessage: Message = {
    id: uuidv4(),
    chatId,
    senderId: session.user.id, // Use the user ID from the session
    profiles: { type: 'customer' },
    content: { text: content },
    created_at: new Date(),
  }

  const response = await axios.post(
    `${API_BASE_URL}/chats/${chatId}/messages`,
    newMessage,
    {
      headers: {
        Authorization: `Bearer ${session.access_token}`,
      },
    }
  )

  return response.data
}

export const getProfileInfo = async (
  session: Session
): Promise<{ data: Profile }> => {
  // Make an API call to fetch profile info
  const response = await axios.get(`${API_BASE_URL}/profile`, {
    headers: {
      Authorization: `Bearer ${session.access_token}`, // Include the session token
    },
  })
  return response.data
}

export const getTransactionsUserBusiness = async (
  userId: string,
  businessId: string,
  session: Session
): Promise<{ data: Transaction[] }> => {
  const response = await axios.post(
    `${API_BASE_URL}/transactions`,
    {
      userId,
      businessId,
    },
    {
      headers: {
        Authorization: `Bearer ${session.access_token}`,
        'Content-Type': 'application/json', // Explicitly set JSON content type
      },
    }
  )
  return response.data
}

export const processDocument = async (
  file: File,
  parameters: any
): Promise<any> => {
  const formData = new FormData()
  formData.append('file', file)
  formData.append('parameters', JSON.stringify(parameters))

  const response = await axios.post(
    `${API_BASE_URL}/documents/extractDoc`,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }
  )

  return response.data
}

/**
 * Get the chat ID between the authenticated user and a given business.
 * Returns { chatId: string | null } where chatId is null if no chat exists.
 *
 * @param {string} businessId - Business id as a string (numeric id in string format)
 * @param {Session} session - The user's authenticated session
 * @returns {Promise<{ chatId: string | null }>}
 */
export const findChatWithBusiness = async (
  businessId: string,
  session: Session
): Promise<string | null> => {
  const response = await axios.get(
    `${API_BASE_URL}/chats?businessId=${businessId}`,
    {
      headers: {
        Authorization: `Bearer ${session.access_token}`,
      },
    }
  )
  return response.data.chatId
}

/**
 * Create a new chat with a business.
 * Returns the newly created chat ID.
 *
 * @param {string} businessId - Business id as a string
 * @param {Session} session - The user's authenticated session
 * @returns {Promise<string>} The new chat ID
 */
export const createChatWithBusiness = async (
  businessId: string,
  session: Session
): Promise<string> => {
  const response = await axios.post(
    `${API_BASE_URL}/chats`,
    { businessId },
    {
      headers: {
        Authorization: `Bearer ${session.access_token}`,
      },
    }
  )
  return response.data.chatId
}

// TODO: Remove this once we handle on server side
interface PlaceCallPayload {
  provider: 'RETELL' | 'HOTLINES'
  to_number: string
  from_number: string
  agent_id: string
  variables: Record<string, string>
  batch_id?: string
  contact_id?: string
}

export const placeCall = async (
  payload: PlaceCallPayload,
  session: Session
): Promise<any> => {
  const response = await axios.post(
    `${API_BASE_URL}/v1/dialer/calls`,
    payload,
    {
      headers: {
        Authorization: `Bearer ${session.access_token}`,
        'Content-Type': 'application/json',
      },
    }
  )
  return response.data
}
