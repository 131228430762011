import { Avatar, Button, Card, Flex, Heading, Text } from '@radix-ui/themes'
import React from 'react'
import { BusinessActions } from '../../types'

interface CarCardProps {
  number_plate: string
  last_service: string
  icon: string
  model: string
  actions: BusinessActions[]
  onActionClick: (
    action: BusinessActions,
    processedActionString: string
  ) => void // Callback to parent
}

const CarCard: React.FC<CarCardProps> = ({
  number_plate,
  last_service,
  icon,
  model,
  actions,
  onActionClick,
}) => {
  const carData = { number_plate, last_service, model }
  const processActionString = (action: BusinessActions) => {
    return action.action.replace(/\$\{(\w+)\}/g, (_, key) => {
      return carData[key as keyof typeof carData] || `UNKNOWN_${key}`
    })
  }
  return (
    <Card className="mb-4 w-full shadow-sm" variant="surface">
      <Flex direction="column" gap="4">
        {/* Title Section */}
        <Flex justify="between" align="center">
          <Heading size="3">{model}</Heading>
        </Flex>

        {/* Car Details Section */}
        <Flex align="center" gap="4">
          {/* Car Icon */}
          <Avatar
            src={icon}
            alt="Car"
            fallback="🚗"
            size="6"
            className="rounded-lg border border-gray-200 shadow-sm"
          />

          {/* Car Info */}
          <div>
            <Heading size="3">{number_plate}</Heading>
            <Text color="gray">Last Service: {last_service}</Text>
          </div>
        </Flex>
        {/* Action Buttons Section */}
        {actions.length > 0 && (
          <Flex gap="2" wrap="wrap">
            {actions.map((action: BusinessActions) => (
              <Button
                size="3"
                radius="large"
                key={action.name}
                variant="surface"
                onClick={() =>
                  onActionClick(action, processActionString(action))
                }
              >
                {action.name}
              </Button>
            ))}
          </Flex>
        )}
      </Flex>
    </Card>
  )
}

export default CarCard
