import { Session } from '@supabase/supabase-js'
import React from 'react'
import { supabase } from '../lib/supabaseClient'
import { Profile } from '../services/api'
import PageContainer from './PageContainer'

interface ProfilePageProps {
  session: Session | null
  profile: Profile | null
  onSignOut: () => void
}

const ProfilePage: React.FC<ProfilePageProps> = ({
  session,
  profile,
  onSignOut,
}) => {
  const handleSignOut = async () => {
    try {
      // Clear local storage first
      localStorage.clear()

      // Attempt to sign out from Supabase
      const { error } = await supabase.auth.signOut()
      if (error) {
        console.error('Supabase signOut error:', error)
      }

      // Call onSignOut regardless of Supabase error
      onSignOut()

      // Optional: Force reload the page to clear any lingering state
      window.location.reload()
    } catch (error) {
      console.error('Error signing out:', error)
      // Still call onSignOut even if there's an error
      onSignOut()
    }
  }

  if (!session) {
    return <div>Please log in</div>
  }

  return (
    <PageContainer session={session} profile={profile}>
      <div className="flex-grow p-4 pt-0">
        <div className="bg-white rounded-lg shadow-md p-6 space-y-4 mt-4">
          <div>
            <label className="block text-gray-600 font-semibold mb-2">
              Name
            </label>
            <p className="text-gray-900 text-lg">
              {profile?.first_name || 'Not set'}
            </p>
          </div>

          <div>
            <label className="block text-gray-600 font-semibold mb-2">
              Email
            </label>
            <p className="text-gray-900 text-lg">{session.user.email}</p>
          </div>

          <button
            onClick={handleSignOut}
            className="w-full bg-blue-500 text-white py-3 rounded-lg hover:bg-blue-600 transition duration-300"
          >
            Sign Out
          </button>
        </div>
      </div>
    </PageContainer>
  )
}

export default ProfilePage
