import { ChatBubbleIcon, GearIcon, HomeIcon } from '@radix-ui/react-icons'
import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

const navItems = [
  { path: '/', label: 'Home', icon: <HomeIcon width="18" height="18" /> },
  {
    path: '/chats',
    label: 'Chats',
    icon: <ChatBubbleIcon width="18" height="18" />,
  },
  {
    path: '/profile',
    label: 'Profile',
    icon: <GearIcon width="18" height="18" />,
  },
]

const BottomNavigation: React.FC = () => {
  const navigate = useNavigate()
  const location = useLocation()

  return (
    <nav className="flex w-full bg-white border-t pb-5">
      <div className="flex w-full justify-between py-3 gap-x-4">
        {navItems.map(({ path, label, icon }) => {
          const isActive = location.pathname === path
          return (
            <button
              key={path}
              onClick={() => navigate(path)}
              className={`flex-1 flex flex-col items-center transition-colors ${
                isActive ? 'text-blue-500' : 'text-gray-800'
              }`}
            >
              {icon}
              <span className="text-xs mt-1">{label}</span>
            </button>
          )
        })}
      </div>
    </nav>
  )
}

export default BottomNavigation
