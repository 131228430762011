import '@radix-ui/themes/styles.css'
import { Session } from '@supabase/supabase-js'
import { useEffect, useState } from 'react'
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from 'react-router-dom'
import AppFeed from './components/AppFeed'
import AuthComponent from './components/Auth'
import BusinessProfile from './components/BusinessProfilePage'
import ChatContainer from './components/ChatContainer'
import ChatList from './components/ChatList'
import LandingPage from './components/LandingPage'
import LoadingComponent from './components/LoadingComponent'
import ProfilePage from './components/ProfilePage'
import { supabase } from './lib/supabaseClient'
import { getProfileInfo, Profile } from './services/api'

function App() {
  const [session, setSession] = useState<Session | null>(null)
  const [profile, setProfile] = useState<Profile | null>(null)
  const [loading, setLoading] = useState(true) // Add this line

  useEffect(() => {
    const fetchSession = async () => {
      setLoading(true) // Set loading to true when starting to fetch
      const {
        data: { session },
      } = await supabase.auth.getSession()
      setSession(session)
      if (session) {
        try {
          const profileData = await getProfileInfo(session)
          setProfile(profileData.data)
        } catch (error) {
          console.error('Error fetching profile info:', error)
        }
      }
      setLoading(false) // Set loading to false when done
    }

    fetchSession()

    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange(async (_event, session) => {
      setLoading(true) // Set loading to true when auth state changes
      setSession(session)
      if (session) {
        try {
          const profileData = await getProfileInfo(session)
          setProfile(profileData.data)
        } catch (error) {
          console.error('Error fetching profile info:', error)
        }
      } else {
        setProfile(null)
      }
      setLoading(false) // Set loading to false when done
    })

    return () => subscription.unsubscribe()
  }, [])

  const handleSignOut = () => {
    setSession(null)
    setProfile(null)
  }

  // If still loading, show loading component
  if (loading) {
    return <LoadingComponent />
  }

  return (
    <Router>
      <div className="App">
        <Routes>
          <Route
            path="/"
            element={
              session ? (
                <AppFeed session={session} profile={profile} />
              ) : (
                <LandingPage />
              )
            }
          />
          <Route
            path="/login"
            element={!session ? <AuthComponent /> : <Navigate to="/" />}
          />
          <Route
            path="/business/:businessId"
            element={
              session ? (
                <BusinessProfile session={session} profile={profile} />
              ) : (
                <Navigate to="/login" />
              )
            }
          />
          <Route
            path="/chats"
            element={
              session ? (
                <ChatList profile={profile} session={session} />
              ) : (
                <Navigate to="/login" />
              )
            }
          />
          <Route
            path="/profile"
            element={
              session ? (
                <ProfilePage
                  session={session}
                  profile={profile}
                  onSignOut={handleSignOut}
                />
              ) : (
                <Navigate to="/login" />
              )
            }
          />
          <Route
            path="/chat/:chatId"
            element={
              session ? (
                <ChatContainer session={session} />
              ) : (
                <Navigate to="/login" />
              )
            }
          />
        </Routes>
      </div>
    </Router>
  )
}

export default App
