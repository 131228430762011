import { ClockIcon, PersonIcon } from '@radix-ui/react-icons'
import { Badge, Button, Card, Flex, Heading, Text } from '@radix-ui/themes'
import React from 'react'
import { BusinessActions } from '../../types'

interface TableCardProps {
  date: string
  time: string
  people: string
  status: string
  actions: BusinessActions[]
  onActionClick: (
    action: BusinessActions,
    processedActionString: string
  ) => void // Callback to parent
}

const TableCard: React.FC<TableCardProps> = ({
  date,
  time,
  people,
  status,
  actions,
  onActionClick,
}) => {
  const tableData = { date, time, people, status }

  const processActionString = (action: BusinessActions) => {
    return action.action.replace(/\$\{(\w+)\}/g, (_, key) => {
      return tableData[key as keyof typeof tableData] || `UNKNOWN_${key}`
    })
  }
  return (
    <Card className="mb-4 w-full shadow-sm" variant="surface">
      {/* Flex Column Layout */}
      <Flex direction="column" gap="4">
        {/* Title Section */}
        <Flex justify="between" align="center">
          <Heading size="3">Reservation</Heading>
          <Badge color={status === 'Confirmed' ? 'green' : 'red'}>
            {status}
          </Badge>
        </Flex>

        {/* Main Content Section */}
        <Flex direction="column" gap="2">
          <Text weight="bold" size="3" className="flex items-center gap-2">
            <ClockIcon /> {date} at {time}
          </Text>
          <Text color="gray" className="flex items-center gap-2">
            <PersonIcon /> {people} People
          </Text>
        </Flex>

        {/* Action Buttons Section */}
        {actions.length > 0 && (
          <Flex gap="2" wrap="wrap">
            {actions.map((action: BusinessActions) => (
              <Button
                size="3"
                radius="large"
                key={action.name}
                variant="surface"
                onClick={() =>
                  onActionClick(action, processActionString(action))
                }
              >
                {action.name}
              </Button>
            ))}
          </Flex>
        )}
      </Flex>
    </Card>
  )
}

export default TableCard
