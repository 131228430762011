// AppHeader.tsx
import { ArrowLeftIcon } from '@radix-ui/react-icons'
import { IconButton } from '@radix-ui/themes'
import { Session } from '@supabase/supabase-js'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import logo from '../assets/png/favicon-8.png'
import { Profile } from '../services/api'
import ProfileIndicator from './ProfileIndicator'

// Add props interface
interface AppHeaderProps {
  session: Session | null
  profile: Profile | null
  leftElement?: string
  isTransparent?: boolean
}

const AppHeader: React.FC<AppHeaderProps> = ({
  session,
  profile,
  leftElement = 'icon',
  isTransparent = false,
}) => {
  const navigate = useNavigate()

  const handleProfileClick = () => {
    navigate('/profile')
  }

  const renderLeftElement = (leftElement: string) => {
    switch (leftElement) {
      case 'icon':
        return (
          <img
            src={logo}
            alt="hotlines.ai"
            className={`h-8 w-8 transition-all duration-300`}
            onClick={() => navigate('/')}
          />
        )
      case 'back':
        return (
          <IconButton
            variant="ghost"
            className="text-gray-500"
            onClick={() => navigate(-1)}
          >
            <ArrowLeftIcon width="24" height="24" />
          </IconButton>
        )
      case 'none':
        return (
          <img
            src={logo}
            alt="hotlines.ai"
            className={`h-8 w-8 transition-all opacity-0 duration-300`}
            onClick={() => navigate('/')}
          />
        )
    }
  }

  return (
    <header
      className={`sticky top-0 z-10 ${isTransparent ? 'bg-transparent' : 'bg-white border-b border-gray-200'}`}
    >
      <div className="flex items-center justify-between px-4 py-2">
        {renderLeftElement(leftElement)}

        <div onClick={handleProfileClick}>
          {session ? (
            <ProfileIndicator session={session} profile={profile} />
          ) : (
            <div className="w-8 h-8 rounded-full bg-gray-200"></div>
          )}
        </div>
      </div>
    </header>
  )
}

export default AppHeader
