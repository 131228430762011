import { Session } from '@supabase/supabase-js'

type ScenarioHandler = {
  trigger: string;
  handler: (message: string, session: Session) => Promise<string | void>;
}

const scenarios: ScenarioHandler[] = [
  // Add more scenarios as needed:
  // {
  //   trigger: 'another trigger',
  //   handler: (message: string) => {
  //     // Handle another special case
  //   }
  // }
];

// Note: For now there are no special scenarios, keeping this around in case we want it for demos.
export const handleSpecialScenarios = async (message: string, session: Session): Promise<string | void> => {
  const lowercaseMessage = message.toLowerCase();
  
  for (const scenario of scenarios) {
    if (lowercaseMessage.includes(scenario.trigger)) {
      return scenario.handler(message, session);
    }
  }
}; 