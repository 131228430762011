import { Session } from '@supabase/supabase-js'
import React, { useEffect, useState } from 'react'
import { Profile } from '../services/api'
import BusinessProfileDesktop from './BusinessProfileDesktop'
import BusinessProfileMobile from './BusinessProfileMobile'

export interface BusinessProfileProps {
  session: Session
  profile: Profile | null
}

const BusinessProfile: React.FC<BusinessProfileProps> = ({
  session,
  profile,
}) => {
  const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth < 768)

  useEffect(() => {
    // Function to update state based on screen size
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768)
    }

    // Attach event listener
    window.addEventListener('resize', handleResize)

    // Cleanup on unmount
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return isMobile ? (
    <BusinessProfileMobile session={session} profile={profile} />
  ) : (
    <BusinessProfileDesktop session={session} profile={profile} />
  )
}

export default BusinessProfile
