import { Session } from '@supabase/supabase-js'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import favicon from '../assets/png/favicon-9.png'
import { getBusinessList, Profile } from '../services/api'
import { Business } from '../types'
import LoadingComponent from './LoadingComponent'
import PageContainer from './PageContainer'

interface AppFeedProps {
  session: Session
  profile: Profile | null
}

const AppFeed: React.FC<AppFeedProps> = ({ session, profile }) => {
  const [businesses, setBusinesses] = useState<Business[]>([])
  const [loading, setLoading] = useState(true)
  const [searchTerm, setSearchTerm] = useState('')
  const [isSearchFocused, setIsSearchFocused] = useState(false)

  useEffect(() => {
    const fetchBusinesses = async () => {
      try {
        setLoading(true)
        const response = await getBusinessList()
        setBusinesses(response.data)
      } catch (error) {
        console.error('Error fetching business list', error)
      } finally {
        setLoading(false)
      }
    }
    fetchBusinesses()
  }, [])

  // **Filter businesses based on search term (case-insensitive)**
  const filteredBusinesses = businesses.filter((business) =>
    business.name.toLowerCase().includes(searchTerm.toLowerCase())
  )

  if (loading) {
    return (
      <PageContainer
        session={session}
        profile={profile}
        headerLeftElement={isSearchFocused ? 'icon' : 'none'}
        headerTransparent={true}
      >
        <LoadingComponent />
      </PageContainer>
    )
  } else
    return (
      <PageContainer
        session={session}
        profile={profile}
        headerLeftElement={isSearchFocused ? 'icon' : 'none'}
        headerTransparent={true}
      >
        {/* Main Content */}
        <main
          className={`flex flex-col items-center justify-start px-4 pb-16 pt-3 max-h-screen transition-all ${
            isSearchFocused ? 'pt-6 ' : 'pt-40'
          }`}
        >
          <div className="flex flex-col space-y-6 items-center w-full max-w-md mx-4">
            {/* Hide favicon + title when search is active */}
            {!isSearchFocused && (
              <div className="flex flex-col items-center transition-opacity duration-600 ease-in-out">
                <img src={favicon} alt="Favicon" className="mb-4 w-16 h-16" />
                <p className="text-xl font-semibold">hotlines.ai</p>
              </div>
            )}

            {/* Search Bar - Moves Up when searching */}
            <div
              className={`flex bg-white w-full rounded-xl px-4 py-2 shadow-sm items-center transition-all duration-600 ease-in-out ${
                isSearchFocused ? 'flex transform' : ''
              }`}
            >
              <input
                type="text"
                placeholder="Search for any business"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                onFocus={() => setIsSearchFocused(true)}
                onBlur={() => {
                  setTimeout(() => {
                    setIsSearchFocused(false)
                    setSearchTerm('')
                  }, 200)
                }}
                className="bg-transparent w-full outline-none"
              />
              {searchTerm && (
                <button
                  onClick={() => setSearchTerm('')}
                  className="text-gray-500"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              )}
            </div>

            {/* Results Section */}
            {!loading && searchTerm.length > 0 && (
              <div className="w-full mt-4">
                {filteredBusinesses.length > 0 ? (
                  <ul className="space-y-4 w-full">
                    {filteredBusinesses.map((business) => (
                      <li
                        key={business.id}
                        className="bg-white p-4 rounded-2xl shadow-sm transition-transform duration-200 hover:scale-105"
                      >
                        <Link to={`/business/${business.id}`} className="block">
                          <p className="text-lg font-medium">{business.name}</p>
                          <p className="text-sm text-gray-500">
                            {business.username}
                          </p>
                        </Link>
                      </li>
                    ))}
                  </ul>
                ) : (
                  <p className="text-gray-500 mt-4">No results found.</p>
                )}
              </div>
            )}
          </div>
        </main>
      </PageContainer>
    )
}

export default AppFeed
