import {
  ArrowLeftIcon,
  ChatBubbleIcon,
  PaperPlaneIcon,
} from '@radix-ui/react-icons'
import {
  Badge,
  Box,
  Button,
  Card,
  Flex,
  Heading,
  IconButton,
  Separator,
  Text,
} from '@radix-ui/themes'
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import useMessagingManager from '../hooks/useMessagingManager'
import { getBusinessInfo, getTransactionsUserBusiness } from '../services/api'
import {
  Business,
  BusinessActions,
  BusinessType,
  CarTransaction,
  FlightTransaction,
  TableTransaction,
  Transaction,
} from '../types'
import { handleSpecialScenarios } from '../utils/specialScenarioHandler'
import BusinessAvatar from './BusinessAvatar'
import CoverImage from './BusinessProfile/CoverImage'
import { BusinessProfileProps } from './BusinessProfilePage'
import LoadingComponent from './LoadingComponent'
import PageContainer from './PageContainer'
import ProfileIndicator from './ProfileIndicator'
import RestaurantCarousel from './RestaurantCarousel'
import CarCard from './TransactionCards/CarCard'
import FlightCard from './TransactionCards/FlightCard'
import TableCard from './TransactionCards/TableCard'

const BusinessProfileDesktop: React.FC<BusinessProfileProps> = ({
  session,
  profile,
}) => {
  const { businessId } = useParams<{ businessId: string }>()
  const navigate = useNavigate()

  const [businessData, setBusinessData] = useState<Business | null>(null)
  const [transactionData, setTransactionData] = useState<Transaction[] | null>(
    null
  )
  const [loading, setLoading] = useState(true)
  const [message, setMessage] = useState<string>('')

  const {
    chatId,
    isCreatingChat,
    isMessageSending,
    sendMessage,
    handleUserMessageTyping,
  } = useMessagingManager(businessId ?? '', session)

  const handleActionClick = async (
    action: BusinessActions,
    processedActionString?: string
  ) => {
    if (processedActionString) {
      handleSendMessage(processedActionString)
    } else {
      handleSendMessage(action.action)
    }
  }

  const handleSendMessage = async (content: string = '') => {
    if (message.length === 0 && content.length === 0) return

    const messageToSend = content || message
    await sendMessage(messageToSend, async (newChatId) => {
      await handleSpecialScenarios(messageToSend, session)
      navigate(`/chat/${newChatId}`, { state: { businessInfo: businessData } })
    })

    setMessage('') // Clear input after sending
  }

  useEffect(() => {
    const fetchBusiness = async () => {
      try {
        setLoading(true)

        const [businessResponse, transactionResponse] = await Promise.all([
          getBusinessInfo(businessId ?? '').catch((error) => {
            console.error('Error fetching business info:', error)
            return { data: null }
          }),
          getTransactionsUserBusiness(
            session.user.id,
            businessId ?? '',
            session
          ).catch((error) => {
            console.error('Error fetching transactions:', error)
            return { data: [] }
          }),
        ])

        setBusinessData(businessResponse.data)
        setTransactionData(transactionResponse.data)
      } catch (error) {
        console.error('Error in concurrent fetching Promise.all:', error)
      } finally {
        setLoading(false)
      }
    }
    fetchBusiness()
  }, [businessId, session])

  if (loading) {
    return (
      <PageContainer
        session={session}
        profile={profile}
        headerLeftElement="icon"
      >
        <LoadingComponent />
      </PageContainer>
    )
  }

  // Handle case when data is not loaded.
  if (!businessData || !transactionData) {
    return (
      <PageContainer
        session={session}
        profile={profile}
        headerLeftElement="back"
        headerTransparent={false}
      >
        <div className="p-4">
          <h1 className="text-xl font-semibold">Business not found</h1>
          <p className="text-gray-600 mt-2">
            The business you're looking for doesn't exist or has been removed.
          </p>
        </div>
      </PageContainer>
    )
  }

  return (
    <div
      className={'flex flex-col justify-between min-h-screen'}
      style={{
        background: 'linear-gradient(to bottom, #EDF5FE, #F4F2FB, #FBF0F7)',
        minHeight: '100vh',
      }}
    >
      {/* Transparent Top Bar */}
      <div className="absolute top-0 left-0 w-full flex justify-between items-center p-4 bg-transparent z-50">
        {/* Back Button */}
        <IconButton
          onClick={() => navigate(-1)}
          variant="surface"
          aria-label="Go Back"
        >
          <ArrowLeftIcon width="24" height="24" />
        </IconButton>

        <ProfileIndicator session={session} profile={profile} />
      </div>
      {/* Cover Image */}
      <CoverImage businessData={businessData} />

      {/* Scrollable Content Wrapper */}
      <div className="flex -mt-80 justify-center px-4 py-10">
        <Card
          className="absolute top-40 left-1/2 -translate-x-1/2 w-full max-w-4xl p-6"
          variant="surface"
        >
          <Box p="4">
            <Flex gap="4" align="center" justify="between">
              <Flex gap="4" align="center">
                <BusinessAvatar
                  src={businessData.avatar}
                  username={businessData.username}
                  size="lg"
                />
                <Box>
                  <Flex align="center" gap="2">
                    <Heading>{businessData.name}</Heading>
                    {businessData.type === BusinessType.AI && (
                      <Badge variant="outline" color="blue" radius="full">
                        AI
                      </Badge>
                    )}
                  </Flex>
                  <Text>{businessData.username}</Text>
                </Box>
              </Flex>
              {chatId && (
                <div className="flex px-4">
                  <Button
                    radius="large"
                    size="3"
                    variant="surface"
                    color="blue"
                    onClick={() => {
                      // Navigate to chat using the retrieved or existing chat ID
                      navigate(`/chat/${chatId || businessId}`, {
                        state: { businessInfo: businessData },
                      })
                    }}
                  >
                    <ChatBubbleIcon />
                    View past interactions
                  </Button>
                </div>
              )}
            </Flex>
            <Flex
              maxWidth="100%"
              mt="4"
              align="center"
              justify="between"
              gap="2"
            >
              <div>{businessData.description}</div>
            </Flex>
          </Box>
          <Separator my="3" size="4" />

          <Flex gap="4" justify="center">
            {/* Carousel */}
            {businessData.carousel_1 && (
              <Flex width="50%" direction="column" gap="2" align="center">
                {businessData.carousel_1 && (
                  <RestaurantCarousel photosArray={businessData.carousel_1} />
                )}
                {businessData.carousel_2 && (
                  <RestaurantCarousel photosArray={businessData.carousel_2} />
                )}
              </Flex>
            )}
            <Flex width="50%" gap="2" direction="column" align="center">
              <Card
                className="flex flex-col shadow-sm w-full mx-auto"
                variant="surface"
              >
                {/* Chat Header */}
                <Heading
                  size="3"
                  className="bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500
               text-transparent bg-clip-text font-extrabold tracking-tight mb-4"
                >
                  Start a chat ✨
                </Heading>

                {/* Action Buttons */}
                {businessData.actions?.length > 0 && (
                  <Flex wrap="wrap" gap="2">
                    {businessData.actions.map((action: BusinessActions) => (
                      <Button
                        size="3"
                        radius="large"
                        key={action.name}
                        variant="surface"
                        onClick={() => handleActionClick(action)}
                      >
                        {action.name}
                      </Button>
                    ))}
                  </Flex>
                )}

                {/* Search Input + Send Button */}
                <Flex justify="between" align="center" gap="3" mt="4">
                  <div className="flex-grow flex bg-white rounded-xl px-4 py-2 shadow-sm border border-gray-300">
                    <input
                      type="text"
                      placeholder="How can we help you today?"
                      value={message}
                      onChange={(e) => handleUserMessageTyping(e, setMessage)}
                      className="bg-transparent w-full outline-none"
                    />
                  </div>
                  <IconButton
                    onClick={() => handleSendMessage()}
                    loading={isMessageSending || isCreatingChat}
                    aria-label="Send Message"
                    disabled={!message.length}
                  >
                    <PaperPlaneIcon />
                  </IconButton>
                </Flex>
              </Card>

              <Separator my="3" size="4" />
              {/* Transactions Section */}

              {(transactionData || []).length === 0 ? (
                <div />
              ) : (
                <Flex
                  align="center"
                  justify="start"
                  gap="1"
                  p="1"
                  className="w-full"
                >
                  <Text size="4" weight="bold">
                    Highlights
                  </Text>
                  <Badge radius="full">{transactionData.length}</Badge>
                </Flex>
              )}

              <Flex
                dir="col"
                align="start"
                justify="start"
                gap="4"
                className="w-full"
              >
                {(transactionData || []).length === 0 ? (
                  <div />
                ) : (
                  transactionData.map((data) => {
                    switch (data.type) {
                      case 'flight':
                        const flight = data as FlightTransaction
                        return (
                          <FlightCard
                            key={flight.id}
                            from={flight.data.from}
                            to={flight.data.to}
                            date={flight.data.date}
                            time={flight.data.time}
                            status={flight.data.status}
                            number={flight.data.number}
                            pnr={flight.data.pnr}
                            toImage={flight.data.toImage}
                            actions={flight.actions}
                            onActionClick={handleActionClick}
                          />
                        )
                      case 'table':
                        const table = data as TableTransaction
                        return (
                          <TableCard
                            key={table.id}
                            date={table.data.date}
                            time={table.data.time}
                            people={table.data.people}
                            status={table.data.status}
                            actions={table.actions}
                            onActionClick={handleActionClick}
                          />
                        )
                      case 'car':
                        const car = data as CarTransaction
                        return (
                          <CarCard
                            key={car.id}
                            number_plate={car.data.number_plate}
                            last_service={car.data.last_service}
                            icon={car.icon}
                            model={car.data.model}
                            actions={car.actions}
                            onActionClick={handleActionClick}
                          />
                        )
                      default:
                        return null
                    }
                  })
                )}
              </Flex>
            </Flex>
          </Flex>
        </Card>
      </div>
    </div>
  )
}

export default BusinessProfileDesktop
