import { PaperPlaneIcon } from '@radix-ui/react-icons'
import {
  Avatar,
  Badge,
  Button,
  Card,
  Flex,
  Heading,
  Text,
} from '@radix-ui/themes'
import React from 'react'
import { BusinessActions } from '../../types'

interface FlightCardProps {
  from: string
  to: string
  date: string
  time: string
  status: string
  number: string
  toImage: string
  pnr: string
  actions: BusinessActions[]
  onActionClick: (
    action: BusinessActions,
    processedActionString: string
  ) => void // Callback to parent
}

const FlightCard: React.FC<FlightCardProps> = ({
  from,
  to,
  date,
  time,
  status,
  number,
  pnr,
  toImage,
  actions,
  onActionClick,
}) => {
  const flightData = { from, to, date, time, status, number, pnr }

  const processActionString = (action: BusinessActions) => {
    return action.action.replace(/\$\{(\w+)\}/g, (_, key) => {
      return flightData[key as keyof typeof flightData] || `UNKNOWN_${key}`
    })
  }

  return (
    <Card className="mb-4 w-full shadow-sm" variant="surface">
      {/* Flex Column Layout */}
      <Flex direction="column" gap="4">
        {/* Title Section */}
        <Flex justify="between" align="center">
          <Heading size="3">Flight</Heading>
          <Badge
            color={
              status === 'On Time' || status === 'Confirmed'
                ? 'green'
                : status === 'Delayed'
                  ? 'red'
                  : 'gray'
            }
          >
            {status}
          </Badge>
        </Flex>

        {/* Main Content Section */}
        <Flex align="center" gap="4">
          {/* Flight Image */}
          <Avatar
            src={toImage}
            alt={to}
            fallback={<PaperPlaneIcon width="24" height="24" />}
            size="6"
            className="rounded-lg border border-gray-200 shadow-sm"
          />

          {/* Flight Info */}
          <div>
            <Heading size="3">
              {number} : {from} → {to}
            </Heading>
            <Text color="gray">
              {date} at {time}
            </Text>
            <br />
            <Text color="gray">{pnr}</Text>
          </div>
        </Flex>

        {/* Action Buttons Section */}
        {actions.length > 0 && (
          <Flex gap="2" wrap="wrap">
            {actions.map((action: BusinessActions) => (
              <Button
                size="3"
                radius="large"
                key={action.name}
                variant="surface"
                onClick={() =>
                  onActionClick(action, processActionString(action))
                }
              >
                {action.name}
              </Button>
            ))}
          </Flex>
        )}
      </Flex>
    </Card>
  )
}

export default FlightCard
