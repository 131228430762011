import React from 'react'

interface BusinessAvatarProps {
  src?: string | null
  username: string
  size?: 'sm' | 'md' | 'lg'
  className?: string
}

const BusinessAvatar: React.FC<BusinessAvatarProps> = ({
  src,
  username,
  size = 'md',
  className = '',
}) => {
  const sizeClasses = {
    sm: 'w-8 h-8',
    md: 'w-10 h-10',
    lg: 'w-16 h-16',
  }

  const textSizeClasses = {
    sm: 'text-lg',
    md: 'text-xl',
    lg: 'text-3xl',
  }

  const baseClasses =
    'rounded-full flex items-center justify-center flex-shrink-0'
  const finalClasses = `${baseClasses} ${sizeClasses[size]} ${className}`

  if (src) {
    return (
      <div
        className={`${finalClasses} bg-gray-200 overflow-hidden border border-gray-200`}
      >
        <img src={src} alt={username} className="w-full h-full object-cover" />
      </div>
    )
  }

  return (
    <div className={`${finalClasses} bg-gray-200`}>
      <span className={`text-gray-600 font-medium ${textSizeClasses[size]}`}>
        {username[0]?.toUpperCase() || '?'}
      </span>
    </div>
  )
}

export default BusinessAvatar
