import { Badge } from '@radix-ui/themes'
import { Session } from '@supabase/supabase-js'
import React, { useCallback, useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { getChatMessages, sendMessage } from '../services/api'
import { Message } from '../types'
import { BusinessType } from '../types/business'
import BusinessAvatar from './BusinessAvatar'
import LoadingComponent from './LoadingComponent'
import MessagesContainer from './MessagesContainer'
import { handleSpecialScenarios } from '../utils/specialScenarioHandler'

interface ChatContainerProps {
  session: Session
}

const ChatContainer: React.FC<ChatContainerProps> = ({ session }) => {
  const { chatId } = useParams<{ chatId: string }>()
  const navigate = useNavigate()
  const location = useLocation()
  const businessInfo = location.state?.businessInfo
  const [messages, setMessages] = useState<Message[]>([])
  const [newMessage, setNewMessage] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [isSending, setIsSending] = useState(false)

  const fetchMessages = useCallback(async () => {
    if (!chatId) return
    try {
      setIsLoading(true)
      const response = await getChatMessages(chatId, session)
      setMessages(response.data)
    } catch (error) {
      console.error('Error fetching messages:', error)
    } finally {
      setIsLoading(false)
    }
  }, [chatId, session])

  useEffect(() => {
    if (chatId) {
      fetchMessages()
    }
  }, [chatId, fetchMessages])

  const handleSendMessage = async (e: React.FormEvent) => {
    e.preventDefault()
    const currentChatId = chatId
    if (!newMessage.trim() || !currentChatId || isSending) return

    try {
      setIsSending(true)
      // Handle any special scenarios if needed
      await handleSpecialScenarios(newMessage, session)
      
      // Send the message
      await sendMessage(chatId, newMessage, session)
      setNewMessage('')
      await fetchMessages()
    } catch (error) {
      console.error('Error sending message:', error)
    } finally {
      setIsSending(false)
    }
  }

  const handleBackClick = () => {
    navigate(-1)
  }

  const ChatHeader = () => (
    <div className="bg-white border-b border-gray-100 px-4 py-3 flex items-center sticky top-0 z-10">
      <button
        onClick={handleBackClick}
        className="p-2 hover:bg-gray-100 rounded-full transition-colors mr-2"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={2}
          stroke="currentColor"
          className="w-6 h-6 text-gray-600"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18"
          />
        </svg>
      </button>
      <div className="flex items-center">
        <BusinessAvatar
          src={businessInfo?.avatar}
          username={businessInfo?.username || '?'}
          size="md"
        />
        <div className="ml-3">
          <div className="flex items-center space-x-1">
            <h2 className="font-semibold text-gray-900">
              {businessInfo?.name || '???'}
            </h2>
            {businessInfo?.type === BusinessType.AI && (
              <Badge variant="outline" color="blue" radius="full">
                AI
              </Badge>
            )}
          </div>
          <p className="text-sm text-gray-500">{messages.length} messages</p>
        </div>
      </div>
    </div>
  )

  if (isLoading) {
    return (
      <div
        className={`flex flex-col justify-between min-h-screen`}
        style={{
          background: 'linear-gradient(to bottom, #EDF5FE, #F4F2FB, #FBF0F7)',
        }}
      >
        <ChatHeader />
        <LoadingComponent />
      </div>
    )
  }

  return (
    <div
      className={`flex flex-col justify-between min-h-screen`}
      style={{
        background: 'linear-gradient(to bottom, #EDF5FE, #F4F2FB, #FBF0F7)',
      }}
    >
      <ChatHeader />

      <MessagesContainer messages={messages} />

      {/* Message Input */}
      <form
        onSubmit={handleSendMessage}
        className="border-t border-gray-100 p-4 bg-white"
      >
        <div className="flex items-center space-x-4 max-w-4xl mx-auto">
          <input
            type="text"
            value={newMessage}
            onChange={(e) => setNewMessage(e.target.value)}
            placeholder="Type your message..."
            className="flex-1 rounded-full border border-gray-200 px-6 py-3 text-gray-700 focus:outline-none focus:border-blue-400 focus:ring-2 focus:ring-blue-100 transition-all duration-200"
            disabled={isSending}
          />
          <button
            type="submit"
            disabled={!newMessage.trim() || isSending}
            className="bg-blue-600 text-white px-6 py-3 rounded-full hover:bg-blue-700 transition-colors disabled:opacity-50 disabled:cursor-not-allowed flex items-center justify-center min-w-[100px]"
          >
            {isSending ? (
              <div className="w-5 h-5 border-2 border-white border-t-transparent rounded-full animate-spin" />
            ) : (
              'Send'
            )}
          </button>
        </div>
      </form>
    </div>
  )
}

export default ChatContainer
